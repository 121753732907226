import React from "react";
 

import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import imgStyles from "assets/style/image.js"
import { Link } from "@material-ui/core";

function CommunityPage() {
  const UseStyles = makeStyles(styles);
  const UseImgStyles = makeStyles(imgStyles);

  const classes = UseStyles();
  const imgClass = UseImgStyles();

  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          {community && <div style={{ color: 'black', padding: '50px', margin: '10vw auto' }} >
            <h2>Wspólnota {community.name}</h2>
            <img src={`/zdjecia/Wspólnoty/${community.name}.jpg`} alt="" style={{ maxWidth: '100%' }} />
            <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly' }}>
              <div >
                <img src={`/zdjecia/Księża/${community.priest}.jpg`} alt="" style={{ maxWidth: '80%', maxHeight: '200px', margin: '10% auto' }} />
                <h3 style={{ fontWeight: 'bold' }}>Opiekun: </h3>
                <h3>{community.priest}</h3>
              </div>
              <div >
                <img src={`/zdjecia/Liderzy/${community.lider}.jpg`} alt="" style={{ maxWidth: '80%', maxHeight: '200px', margin: '10% auto' }} />
                <h3 style={{ fontWeight: 'bold' }}>Lider: </h3>
                <h3>{community.lider}</h3>
              </div>
            </div>
            {community.meetings &&
              <>
                <h3 style={{ fontWeight: 'bold' }}>Spotkania: </h3>
                <h3>{community.meetings}</h3>
              </>
            }
            <h3 style={{ fontWeight: 'bold' }}>O wspólnocie: </h3>
            <h3>{community.description}</h3>
            {community.comming && community.comming.map((item, idx) => (
              <h3 key={idx}>{item}</h3>))}
            {community.news &&
              <>
                <h3>Aktualności: </h3>
                {community.news.map((item, idx) => (
                  <Link key={idx} href={`/${item.link}`} id={`#${item.link}`}>
                    <img src={`/${item.posterUrl}`} className={imgClass.img} alt={item.alt}/>
                  </Link>
                )
                )}
              </>
            }
          </div>}
        </div>
      </div>
    </Layout>
  );
}

 
  const community = {
    "url": "emaus",
    "name": "EMAUS",
    "lider": "Joanna Kmiecik",
    "priest": "ks. Paweł Lisoń",
    "meetings": "Parafia pw. św. Wawrzyńca w Strzelcach Opolskich, piątek 19:00",
    "description": "Wspólnota Katolickiej Odnowy w Duchu Świętym „Emaus” istnieje od ok. 30 lat. Wspólnie odkrywamy radość bycia chrześcijaninem. Jest nas ok. 20 osób, w różnym wieku, różnego stanu, reprezentujący różne zawody, pochodzących z parafii Strzelec Opolskich i okolic. Spotykamy się raz w tygodniu na wspólnej modlitwie i rozważaniu Słowa Bożego. Jesteśmy grupą otwartą i zapraszamy wszystkich, którzy poszukują Boga. Dbamy o swój rozwój duchowy i formację uczestnicząc w rekolekcjach diecezjalnych i poza diecezją, seminariach, kursach, dniach skupienia, czuwaniach oraz pielgrzymkach. Miejsce spotkań to dom katechetyczny i kościół parafialny. Posługi przez nas podejmowane: W każdy czwartek przed Mszą św. wieczorną prowadzimy różaniec w intencji młodego pokolenia. Posługujemy podczas Mszy św. w pierwszy piątek miesiąca. Włączamy się w modlitwę w intencji miasta Koronką do Miłosierdzia Bożego. Wspólnie z pensjonariuszami Domu Pomocy Społecznej w Strzelcach Op. modlimy się oraz spędzamy czas na budowaniu relacji. Angażujemy się w spotkania z rodzicami dzieci przygotowujących się do przyjęcia pierwszej komunii świętej",
    "news": [
        {
          "link": "emaus-emaus-rekolekcje-odnowy-wiary-2020",
          "posterUrl": "communitiesSlides/emaus/emaus-rekolekcje-odnowy-wiary-2020-zajawka.jpg"
        },{
          "link": "emaus-kolendowanie-2020",
          "posterUrl": "communitiesSlides/emaus/emaus-kolendowanie-2020-zajawka.jpg"
        }
    ]
  }
   

export default CommunityPage;
